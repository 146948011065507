import * as React from 'react';
import * as discourse from '@resi-media/player-discourse';
import { match } from 'ts-pattern';
import { WEBPLAYER_ORIGIN } from '@studio/constants/env-variables';
import { usePrefix } from '@studio/hooks';
import { getEmbedSrcUrl, getManifestSrcUrl, getPlaylistSrcUrl } from './helpers';
import { WebPlayerWrapper } from './styles';
import type { EmbedType } from './types';
import { WebPlayerKind } from './types';

export type WebPlayerPropsBase = {
  autoplay?: boolean;
  dataTestId?: string;
  iframeId?: string;
  onDiscourseReady?: () => void;
  onMounted?: () => void;
  onUnmounted?: () => void;
  startPos?: string;
};

export type EmbedIdOptions = WebPlayerPropsBase & {
  embedId: string;
  kind: WebPlayerKind.EMBED_ID;
  type?: EmbedType;
};

export type ManifestOptions = WebPlayerPropsBase & {
  kind: WebPlayerKind.MANIFEST;
  manifestUrl: string;
};

export type MultipleManifestsOptions = WebPlayerPropsBase & {
  kind: WebPlayerKind.MANIFESTS;
  manifestUrls: {
    dash: string;
    hls: string;
  };
};

export type PlaylistOptions = WebPlayerPropsBase & {
  embedId: string;
  kind: WebPlayerKind.PLAYLIST;
  type?: EmbedType;
};

export const PAGE_SCRIPT_ID = 'page-min-script';
function appendPageMin() {
  document.getElementById(PAGE_SCRIPT_ID)?.remove();
  const script = document.createElement('script');
  script.id = PAGE_SCRIPT_ID;
  script.setAttribute('data-testid', PAGE_SCRIPT_ID);
  script.src = 'https://control.resi.io/webplayer/page.min.js';
  document.body.append(script);
}
export type WebPlayerProps = EmbedIdOptions | ManifestOptions | MultipleManifestsOptions | PlaylistOptions;
const WebPlayer = ({
  dataTestId: dataTestIdProp,
  iframeId: iframeIdProp,
  onDiscourseReady,
  onMounted,
  onUnmounted,
  ...rest
}: WebPlayerProps) => {
  const iframeId = iframeIdProp ?? 'player-frame';
  const dataTestId = dataTestIdProp ?? 'web-player';
  const mounted = React.useRef(false);
  const { commonT } = usePrefix('');

  React.useLayoutEffect(() => {
    appendPageMin();
    return () => {
      // @ts-expect-error value use by page script
      window.pageScriptStarted = false;
      document.getElementById(PAGE_SCRIPT_ID)?.remove();
    };
  }, []);
  React.useEffect(() => {
    if (!mounted.current) {
      discourse.registerListeners(
        (e) => {
          if (e.id === iframeId) {
            onDiscourseReady?.();
          }
        },
        { expectedUrl: WEBPLAYER_ORIGIN }
      );
      onMounted?.();

      mounted.current = true;
    }
  }, [iframeId, onDiscourseReady, onMounted]);

  // Needs to remain in separate useEffect to work properly
  React.useEffect(() => {
    return () => {
      mounted.current = false;
      discourse.clearListeners();
      onUnmounted?.();
    };
  }, [onUnmounted]);

  const getSrcUrl = () =>
    match(rest)
      .with({ kind: WebPlayerKind.EMBED_ID }, (embedIdProps) => getEmbedSrcUrl({ ...embedIdProps, iframeId }))
      .with({ kind: WebPlayerKind.MANIFEST }, (manifestProps) => getManifestSrcUrl({ ...manifestProps, iframeId }))
      .with({ kind: WebPlayerKind.MANIFESTS }, (manifestProps) => getManifestSrcUrl({ ...manifestProps, iframeId }))
      .with({ kind: WebPlayerKind.PLAYLIST }, (playlistProps) => getPlaylistSrcUrl({ ...playlistProps, iframeId }))
      .exhaustive();

  return (
    <WebPlayerWrapper data-testid={dataTestId}>
      <iframe
        allow="autoplay; fullscreen"
        allowFullScreen
        className="resi-video-frame"
        data-testid="player-iframe"
        id={iframeId}
        src={getSrcUrl()}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
        title={commonT('video')}
      />
    </WebPlayerWrapper>
  );
};

WebPlayer.displayName = 'WebPlayer';

export default WebPlayer;
