import { DeploymentUnitOutlined, FolderOutlined } from '@ant-design/icons';
import { Broadcast, Inline, Text } from '@resi-media/resi-ui';

type _Props = {
  planName: string | null;
  vodPlanName: string | null;
  webPlanName: string | null;
};

const PlanTypeIndicator = ({ planName, vodPlanName, webPlanName }: _Props) => {
  return (
    <Inline>
      <Text as="div" colorVariant={planName ? 'primary' : 'disabled'} dataTestId="multisite-indicator" variant="h4">
        <DeploymentUnitOutlined />
      </Text>
      <Text as="div" colorVariant={webPlanName ? 'primary' : 'disabled'} dataTestId="web-indicator" variant="h4">
        <Broadcast />
      </Text>
      <Text as="div" colorVariant={vodPlanName ? 'primary' : 'disabled'} dataTestId="vod-indicator" variant="h4">
        <FolderOutlined />
      </Text>
    </Inline>
  );
};

PlanTypeIndicator.displayName = 'PlanTypeIndicator';

export default PlanTypeIndicator;
