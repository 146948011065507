export const LAUNCHDARKLY_TOGGLES = {
  ARCHIVE_VIDEO_TO_PLAYLISTS: 'archiveVideoToPlaylists',
  AV1_ENCODING: 'av1Encoding',
  MORE_UPLOAD_VIDEO_CODECS: 'moreUploadVideoCodecs',
  DECODER_REMOTE_LOAD: 'decoderRemoteLoad',
  DOWNLOAD_AUDIO: 'downloadAudio',
  ENCODER_AUTO_INPUT: 'encoderAutoInput',
  ENCODER_CUSTOMER_UPDATE: 'encoderCustomerUpdate',
  ENCODER_4K_INPUT: 'encoder4KInput',
  OBS_VISIBLE: 'obsVisible',
  RICH_TEXT_LIBRARY: 'richTextLibrary',
  PENDO_EMBEDDED_GUIDES: 'pendoEmbeddedGuides',
  QRCLICK_TAB: 'qrclickTab',
  STUDIO_ANALYTICS_YOUTUBE: 'studioAnalyticsYoutube',
  STUDIO_UIUX: 'studioUiux',
} as const;
