import { useSelector } from 'react-redux';
import { PROFILE_TYPES } from '@studio/constants/web-channels';
import { selectCustomerData } from '@studio/store';
import { selectRegionItems } from '@studio/store/regions';

const useQrClick = () => {
  const customer = useSelector(selectCustomerData);
  const regionList = useSelector(selectRegionItems);
  const isQrClickPlanSupported = Boolean(customer?.webPlan?.allowQrCodeDetection);
  const hasQrClickOverlaysPlan = Boolean(customer?.webPlan?.allowQrClickOverlays);

  function availableForRegion(regionId: string) {
    const selectedRegion = regionList.find((region) => region.uuid === regionId);
    return Boolean(selectedRegion?.hotspotsServiceUrl);
  }

  return {
    availableForCustomer: isQrClickPlanSupported,
    availableForRegion,
    availableForWebChannel(regionId: string, profileType?: string) {
      if (!profileType || profileType === PROFILE_TYPES.SOCIAL) {
        return false;
      }
      return isQrClickPlanSupported && availableForRegion(regionId);
    },
    qrClickOverlaysAvailable: hasQrClickOverlaysPlan && isQrClickPlanSupported,
  };
};
export default useQrClick;
