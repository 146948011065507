import type { Immutable } from 'immer';
import Permissions from '@studio/store/authentication/permissions';

export enum NavCategory {
  DASHBOARD,
  MEDIA,
  SCHEDULE,
  ANALYTICS,
  SETTINGS,
  ADMIN,
  HELP,
  ACCOUNT,
  API_APPS,
}

export const MenuItems = {
  ACCOUNT_DETAILS: 'accountDetails',
  ACCOUNT_SETTINGS: 'accountSettings',
  ADMIN: 'admin',
  ADMIN_ENCODERS: 'adminEncoders',
  ADMIN_SEARCH: 'orgSearch',
  ANALYTICS: 'analytics',
  API_ACCESS: 'apiAccess',
  BROADCAST_SETTINGS: 'broadcastSettings',
  CURRENT_ORG: 'currentOrg',
  DESTINATION_GROUPS: 'destinationGroups',
  DEVICE_SETTINGS: 'deviceSettings',
  ENCODER_CHANNELS: 'encoderChannels',
  ENCODER_PRESETS: 'encoderPresets',
  ENCODER_VIDEOS: 'encoderVideos',
  ENCODERS: 'encoders',
  EVENT_ANALYTICS: 'eventAnalytics',
  EXPIRING_MEDIA: 'expiringMedia',
  HARDWARE_DASHBOARD: 'dashboard',
  HARDWARE_SEARCH: 'hardwareSearch',
  HARDWARE: 'hardware',
  LIBRARY: 'library',
  LIBRARY_ANALYTICS: 'libraryAnalytics',
  MEDIA: 'media',
  MONITORING: 'monitoring',
  ORGANIZATION_DETAILS: 'organizationDetails',
  OVERVIEW: 'overview',
  PLAYER_PROFILES: 'playerProfiles',
  PLAYLISTS: 'playlists',
  QR_CLICK_OVERLAYS: 'qrClickOverlays',
  RECENTLY_UPLOADED: 'recentlyUploaded',
  RECENT_RTMP: 'recentRtmp',
  RTMP: 'rtmp',
  SAVED_MEDIA: 'savedMedia',
  SCHEDULE: 'schedule',
  SITES: 'sites',
  SOCIAL_MEDIA: 'socialMedia',
  STATUS: 'status',
  SYSTEM_LOGS: 'systemLogs',
  USERS: 'users',
  VENUES: 'venues',
  WEB_CHANNELS: 'webChannels',
  WEB_PRESETS: 'webPresets',
  WEB_SETTINGS: 'webSettings',
  WEB_VIDEOS: 'webVideos',
} as const;

export const MenuPermissions = {
  [MenuItems.ACCOUNT_DETAILS]: { permissions: [] },
  [MenuItems.ACCOUNT_SETTINGS]: { permissions: [Permissions.ORGANIZATION_DETAILS_GET] },
  [MenuItems.ADMIN]: { permissions: [Permissions.RESI_ADMIN_ONLY] },
  [MenuItems.ADMIN_SEARCH]: { permissions: [Permissions.RESI_ADMIN_ONLY] },
  [MenuItems.ADMIN_ENCODERS]: { permissions: [Permissions.RESI_ADMIN_ONLY] },
  [MenuItems.ANALYTICS]: { permissions: [] },
  [MenuItems.API_ACCESS]: { permissions: [Permissions.API_ACCESS_GET] },
  [MenuItems.BROADCAST_SETTINGS]: {
    permissions: [
      Permissions.WEB_ENCODER_PROFILES_GET,
      Permissions.WEB_EVENT_PROFILES_GET,
      Permissions.SOCIAL_ACCTS_GET,
      Permissions.DESTINATION_GROUPS_GET,
    ],
  },
  [MenuItems.CURRENT_ORG]: { permissions: [Permissions.RESI_ADMIN_ONLY] },
  [MenuItems.DESTINATION_GROUPS]: { permissions: [Permissions.DESTINATION_GROUPS_GET] },
  [MenuItems.DEVICE_SETTINGS]: {
    permissions: [
      Permissions.ENCODERS_GET,
      Permissions.ENCODER_PROFILES_GET,
      Permissions.EVENT_PROFILES_GET,
      Permissions.USERS_GET,
    ],
  },
  [MenuItems.ENCODER_CHANNELS]: { permissions: [Permissions.EVENT_PROFILES_GET] },
  [MenuItems.ENCODER_PRESETS]: { permissions: [Permissions.ENCODER_PROFILES_GET] },
  [MenuItems.ENCODERS]: { permissions: [Permissions.ENCODERS_GET] },
  [MenuItems.HARDWARE]: { permissions: [Permissions.HARDWARE_UNITS_GET] },
  [MenuItems.HARDWARE_SEARCH]: { permissions: [Permissions.HARDWARE_UNITS_GET] },
  [MenuItems.HARDWARE_DASHBOARD]: { permissions: [Permissions.HARDWARE_UNITS_DASHBOARD] },
  [MenuItems.LIBRARY]: { permissions: [Permissions.LIBRARY_GET] },
  [MenuItems.LIBRARY_ANALYTICS]: { permissions: [] },
  [MenuItems.MONITORING]: { permissions: [Permissions.RESI_ADMIN_ONLY] },
  [MenuItems.ORGANIZATION_DETAILS]: { permissions: [Permissions.ORGANIZATION_DETAILS_GET] },
  [MenuItems.PLAYER_PROFILES]: {
    permissions: [Permissions.PLAYER_PROFILES_GET],
  },
  [MenuItems.RECENTLY_UPLOADED]: { permissions: [] },
  [MenuItems.RECENT_RTMP]: {
    permissions: [Permissions.RESI_ADMIN_ONLY],
  },
  [MenuItems.RTMP]: {
    permissions: [Permissions.SOCIAL_ACCTS_GET],
  },
  [MenuItems.SCHEDULE]: { permissions: [Permissions.SCHEDULES_GET] },
  [MenuItems.SITES]: { permissions: [Permissions.SITE_GET] },
  [MenuItems.SOCIAL_MEDIA]: { permissions: [Permissions.SOCIAL_ACCTS_GET] },
  [MenuItems.SYSTEM_LOGS]: { permissions: [Permissions.RESI_ADMIN_ONLY] },
  [MenuItems.USERS]: { permissions: [Permissions.USERS_GET, Permissions.INVITED_USERS_GET] },
  [MenuItems.VENUES]: { permissions: [Permissions.USERS_GET] },
  [MenuItems.WEB_CHANNELS]: { permissions: [Permissions.WEB_EVENT_PROFILES_GET] },
  [MenuItems.WEB_PRESETS]: { permissions: [Permissions.WEB_ENCODER_PROFILES_GET] },
  [MenuItems.WEB_SETTINGS]: {
    permissions: [
      Permissions.WEB_ENCODER_PROFILES_GET,
      Permissions.WEB_EVENT_PROFILES_GET,
      Permissions.SOCIAL_ACCTS_GET,
      Permissions.DESTINATION_GROUPS_GET,
    ],
  },
  [MenuItems.WEB_VIDEOS]: { permissions: [Permissions.WEB_EVENTS_GET] },
  [MenuItems.ENCODER_VIDEOS]: { permissions: [Permissions.EVENTS_GET] },
  [MenuItems.PLAYLISTS]: {
    permissions: [Permissions.PLAYLISTS_GET],
  },
} as const;

// Used in Hamburger Menu to control whether to show/hide settings section
export const userHasAnySettingsPerms: Immutable<Permissions[]> = [
  Permissions.ENCODERS_GET,
  Permissions.ENCODER_PROFILES_GET,
  Permissions.EVENT_PROFILES_GET,
  Permissions.WEB_ENCODER_PROFILES_GET,
  Permissions.WEB_EVENT_PROFILES_GET,
  Permissions.SOCIAL_ACCTS_GET,
  Permissions.DESTINATION_GROUPS_GET,
  Permissions.ORGANIZATION_DETAILS_GET,
  Permissions.USERS_GET,
  Permissions.INVITED_USERS_GET,
];
