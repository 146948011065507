import { useTheme } from '@emotion/react';
import { Draft, Inline, ListItem, Stack, Text, useBreakpoint } from '@resi-media/resi-ui';
import DefaultThumbnail from '@studio/images/default-thumbnail.jpg';
import { truncateRow } from '@studio/pages/styles';
import type { Media } from '@studio/types';
import { S } from './styles';

type _Props = {
  onSelectVideo: (video: Media.Get.LibraryItem) => void;
  video: Media.Get.LibraryItem;
};
const LibraryVideoRow = ({ onSelectVideo, video }: _Props) => {
  const theme = useTheme();
  const mediaQuery = useBreakpoint();
  const utils = Draft.useUtils<Date>();
  const hasThumbnail = Boolean(video.thumbnails?.[0].representations[0].publicUrl);
  const thumbnailUrl = video.thumbnails?.[0].representations[0].publicUrl ?? DefaultThumbnail;

  return (
    <ListItem
      dataTestId="video-row"
      onClick={() => onSelectVideo(video)}
      style={{ height: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
    >
      <Inline alignItems="center" gap="m" justifyContent="flex-start" overflow="hidden">
        <div
          css={() => S.imageContainer(theme, hasThumbnail)}
          data-testid={hasThumbnail ? 'thumbnail-img-container' : 'thumbnail-placeholder'}
        >
          <img
            alt={video.title}
            css={(_theme) => S.image(_theme, !mediaQuery.lg ? 100 : 126)}
            data-testid="thumbnail"
            loading="lazy"
            src={thumbnailUrl}
          />
        </div>
        <Stack overflow="hidden">
          <div css={truncateRow(theme, 1)}>
            <Text style={{ wordBreak: 'break-all' }} variant="body1" weightVariant="bold">
              {video.title}
            </Text>
          </div>
          <Text variant="body4">
            {utils.formatByString(new Date(video.startTime), 'EEE, MM/dd/yyyy')}{' '}
            {utils.format(new Date(video.startTime), 'fullTime')}
          </Text>
        </Stack>
      </Inline>
    </ListItem>
  );
};

LibraryVideoRow.displayName = 'LibraryVideoRow';

export default LibraryVideoRow;
